import { Event } from 'core/Event';
import { on } from 'tools/event';
import { debounce } from 'tools/debounce.js';
import breakpoints from 'constants/breakpoints.js';

export const mediaQuery = {
    breakpoints,
    current: null,
    touchable: false,
    classes: {
        touchable: 'h-touchable',
        notTouchable: 'h-not-touchable',
    },

    _init() {
        if (this.init) {
            return;
        }

        this.init = true;
        this.current = this._getCurrentSize();
        this.touchable = this._isTouchableDevice();
        this.rootEl = document.querySelector('html');

        this.bindEvents();

        this.rootEl.classList.toggle(this.classes.touchable, this.touchable);
        this.rootEl.classList.toggle(this.classes.notTouchable, !this.touchable);
    },

    _isTouchableDevice() {
        try {
            document.createEvent('TouchEvent');
            return true;
        } catch (e) {
            return false;
        }
    },

    bindEvents() {
        on('resize.mediaQuery', window, debounce(this.onResize.bind(this), 300));
    },

    onResize() {
        const newSize = this._getCurrentSize();
        const currentSize = this.current;

        if (newSize !== currentSize) {
            // Change the current media query
            this.current = newSize;
            this.touchable = this._isTouchableDevice();
            this.rootEl.classList.toggle(this.classes.touchable, this.touchable);
            this.rootEl.classList.toggle(this.classes.notTouchable, !this.touchable);

            // Broadcast the media query change
            Event.emit('MediaQuery:changed', {
                size: newSize,
                oldSize: currentSize,
            });
        }
    },

    /**
     * Checks if the screen matches to a breakpoint.
     * @function
     * @param {string} breakpoint - Name of the breakpoint to check, either 'small only' or 'small'.
     * Omitting 'only' falls back to using atLeast() method.
     * @returns {boolean} `true` if the breakpoint matches, `false` if it does not.
     */
    is(breakpoint) {
        if (!this.current) {
            this._init();
        }

        const size = breakpoint.toLowerCase().trim().split(' ');
        const sizeLength = size.length;
        const mediaSizeName = size[0];

        if (sizeLength > 1) {
            const query = size[1];
            if (query !== 'only' && query !== 'down') {
                throw new Error('only keyword "only" and "down" are allowed to be used');
            }

            if (query === 'only' && mediaSizeName === this.current) {
                return true;
            }
            if (query === 'down') {
                return this.atMost(mediaSizeName);
            }
        } else {
            return this.atLeast(mediaSizeName);
        }
        return false;
    },

    /**
     * Checks if the screen is at least as wide as a breakpoint.
     * @function
     * @param {string} breakpoint - Name of the breakpoint to check.
     * @returns {Boolean} `true` if the breakpoint matches, `false` if it's smaller.
     */

    atLeast(breakpoint) {
        if (!this.current) {
            this._init();
        }

        return this.matchQuery(breakpoint);
    },

    /**
     * Check if the sreen is at most as wide as the breakpoint
     * @param {string} breakpoint
     * @returns {boolean}
     */
    atMost(breakpoint) {
        if (!this.current) {
            this._init();
        }

        return this.matchQuery(breakpoint, false);
    },

    /**
     * Match query
     * @param {string} breakpoint
     * @param {boolean} isMobileFirst
     * @returns {boolean}
     */
    matchQuery(breakpoint, isMobileFirst = true) {
        if (!this.current) {
            this._init();
        }

        if (!breakpoint || (breakpoint && typeof breakpoint !== 'string')) {
            return false;
        }

        const size = breakpoint.toLowerCase();

        const query = this.get(size, !!isMobileFirst);

        if (query) {
            return window.matchMedia(query).matches;
        }

        return false;
    },

    /**
     * Get breakpoint from breakpointSize
     * @param {string} breakpointSize
     * @returns {Object}
     */
    _getBreakpoint(breakpointSize) {
        if (!breakpointSize || typeof breakpointSize !== 'string') {
            return null;
        }

        const size = breakpointSize.toUpperCase();

        return breakpoints[size];
    },

    /**
     * Gets the media query of a breakpoint.
     * @function
     * @param {string} breakpointSize - Name of the breakpoint to get.
     * @param {boolean} isMobileFirst - Mobile first strategy - Always start from small to larger
     * @returns {string|null} - The media query of the breakpoint,
     *                          or `null` if the breakpoint doesn't exist.
     */
    get(breakpointSize, isMobileFirst = true) {
        if (!this.current) {
            this._init();
        }

        const breakpoint = this._getBreakpoint(breakpointSize);
        if (!breakpoint) {
            return null;
        }

        if (!isMobileFirst && !breakpoint.max) {
            // The largest breakpoint does not have max value.
            // i.e: (large down) will output `only screen and (max-width: 1025px)`
            // It's not relevant to do this but in case.
            return `only screen and (min-width: ${breakpoint.min}px)`;
        }

        const breakpointWidth = isMobileFirst ? breakpoint.min : breakpoint.max;
        if (breakpointWidth || breakpointWidth === 0) {
            return `only screen and (${isMobileFirst ? 'min' : 'max'}-width: ${breakpointWidth}px)`;
        }

        return null;
    },

    /**
     * Gets the current breakpoint name by testing every breakpoint
     * and returning the last one to match (the biggest one).
     * @function
     * @private
     * @returns {string} Name of the current breakpoint.
     */
    _getCurrentSize() {
        let matched;

        Object.keys(breakpoints).forEach((key) => {
            if (matched) {
                return;
            }

            const breakpoint = breakpoints[key];

            let query = 'only screen';

            if (breakpoint.min || breakpoint.min === 0) {
                query += ` and (min-width:  ${breakpoint.min}px)`;
            }

            if (breakpoint.max) {
                query += ` and (max-width:  ${breakpoint.max}px)`;
            }

            if (window.matchMedia(query).matches) {
                matched = breakpoint;
                matched.name = key.toLowerCase();
            }
        });

        if (typeof matched === 'object') {
            return matched.name;
        }

        return matched;
    },
};
