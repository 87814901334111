/* eslint require-jsdoc: 0 */
/* eslint valid-jsdoc: 0 */
/* eslint no-console: 0 */
const registeredListeners = {};

/**
 * Attach event to an HTMLElement or NodeList
 * @param {string} eventName - Name of the event.
 * @param {HTMLElement|NodeList} target - Can be either a selector or a list of selectors
 * @param {function} fn - Listener function
 * @param {Object} options - An options object that specifies
 * characteristics about the event listener. The available options are:
 * @param {boolean} one - event is attached only once and removed after being listened capture, once, passive.
 * See more info: (https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener)
 */
export function on(eventName, target, fn, options = {}, one) {
    if (typeof eventName !== 'string') {
        console.warn(target);
        throw new Error('eventName has to be a string');
    }

    if (!target) {
        return;
    }

    if (typeof fn !== 'function') {
        console.warn(target);
        throw new Error('Handler function is mandatory');
    }

    // Generate and attribute an ID for the element
    const eventId = generateEventId(target);

    // A eventName can be also a list of many event separated by a space like jQuery
    const events = eventName.split(' ');

    events.forEach((event) => {
        if (isEventAttached(target, event)) {
            return;
        }

        if (target instanceof HTMLElement
            || target instanceof Window
            || target instanceof Document
        ) {
            target.eventId = eventId;
            registerListener(event, target, fn, options, one);
        } else if (target instanceof NodeList) {
            [...target].forEach((nodeElement) => {
                nodeElement.eventId = generateEventId(nodeElement);
                registerListener(event, nodeElement, fn, options, one);
            });
        }
    });
}

/**
 * @param {HTMLElement} target
 * @returns {string}
 */
function generateEventId(target) {
    return target.eventId || `event_${Math.random().toString(36).substr(2, 16)}`;
}

/** Attach event once
 * @param {string} eventName - Name of the event.
 * @param {HTMLElement|NodeList} target - Can be either a selector or a list of selectors
 * @param {function} fn - Listener function
 * @param {Object} options - An options object that specifies characteristics about the event listener.
 */
export function once(eventName, target, fn, options = {}) {
    const params = Object.assign(options, { once: true });

    on(eventName, target, fn, params, true);
}

/**
 * Remove event listeners from an element
 * @param {string} eventName - Name of the event.
 * @param {HTMLElement|NodeList} target - Can be either a selector or a list of selectors
 * @param {function} fn - Listener function
 * @param {boolean} capture - If true, forces bubbling on non-bubbling events
 */
export function off(eventName, target, capture = false) {
    let targetEl = target;
    let eventNamespace = eventName;

    // If there is only one parameter and if it's not
    // an eventName - we consider that we should remove
    // all the listeners from that selector(s).
    if (eventNamespace && typeof eventNamespace !== 'string' && arguments.length === 1) {
        targetEl = eventNamespace;
        eventNamespace = 'all';
    }

    eventName
        .split(' ')
        .forEach((event) => {
            if (targetEl instanceof HTMLElement
                || targetEl instanceof Window
                || targetEl instanceof Document
            ) {
                removeEventListener(event, targetEl, capture);
            } else if (targetEl instanceof NodeList) {
                [...targetEl].forEach((nodeElement) => {
                    removeEventListener(event, nodeElement, capture);
                });
            } else {
                throw new Error('HTMLElement or NodeList is required');
            }
        });
}

/**
 * Trigger event
 * @param {string} eventName
 * @param {HTMLElement} element
 * @param {Object} options - see options info: https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
 */
export function trigger(eventName, element, options = {}) {
    const event = new CustomEvent(eventName, {
        bubbles: options.bubbles || false,
        cancelable: options.cancelable || true,
        detail: options,
    });

    element.dispatchEvent(event);
}

/**
 * Registers event listeners for a DOM element
 * @param {string} eventName
 * @param {HTMLElement} target
 * @param {Function} fn - Event handler function
 * @param {Object|boolean} options - Can be an options or a boolean (useCapture)
 * @param {boolean} one - Event is attached once
 * see doc: https://developer.mozilla.org/fr/docs/Web/API/EventTarget/addEventListener
 */
function registerListener(eventName, target, fn, options, one) {
    const { eventId } = target;
    let listener = fn;
    let eventNamespace = eventName;

    if (one) {
        listener = (args) => {
            // if we listen only once, we can remove the event listener right after the callback is executed
            target.removeEventListener(eventNamespace, listener, options);
            return fn(args);
        };
    } else {
        // We don't register the event if it's only triggered once.
        if (!registeredListeners[eventId]) {
            registeredListeners[eventId] = {};
        }

        registeredListeners[eventId][eventNamespace] = {
            listener,
            target,
            options,
        };
    }

    // Event can be namespaced. ie. click.search
    // But you can also have your own custom event but you don't necessarily want it
    // to be split with the customEvent option
    if (typeof options === 'object' && !options.customEvent) {
        [eventNamespace] = eventNamespace.split('.');
    }

    target.addEventListener(eventNamespace, listener, options);
}

/**
 * @param {string} eventName
 * @param {Node} target
 * @param {Object} options
 */
function removeEvent(eventName, target, options) {
    const { eventId } = target;
    const registeredId = registeredListeners[eventId];
    const currentEvent = registeredId[eventName];
    let eventSuffix = '';
    let eventNamespace = eventName;

    if (eventId && registeredId && currentEvent) {
        // Event can be namespaced
        if (currentEvent.options && !currentEvent.options.customEvent) {
            [eventNamespace, eventSuffix] = eventNamespace.split('.');
        }

        delete registeredListeners[eventId][eventNamespace + (eventSuffix ? `.${eventSuffix}` : '')];
        currentEvent.target.removeEventListener(eventNamespace, currentEvent.listener, options);
    }
}

/**
 * @param {string} eventName
 * @param {Node} target
 * @param {boolean|Object} options
 */
function removeEventListener(eventName, target, options = false) {
    const { eventId } = target;
    const registeredId = registeredListeners[eventId];

    if (!registeredId) {
        console.warn(target);
        throw new Error(`Do not remove event ${eventName} that has not been attached`);
    }

    if (eventName === 'all') {
        Object.keys(registeredId).forEach((event) => {
            const element = registeredId[event];
            if (element) {
                element.options = options;
                removeEvent(event, element.target, element.fn, element.options);
            }
        });
    } else {
        removeEvent(eventName, target, options);
    }
}

/**
 * Check if event is already attached to an element
 */
function isEventAttached(target, eventName) {
    const { eventId } = target;

    // Check if listener is already registered or not to an element
    if (eventId && registeredListeners[eventId] && registeredListeners[eventId][eventName]) {
        console.warn(target);
        console.warn(new Error(`The same event (${eventName}) has been already attached to the element`));
        return true;
    }

    return false;
}

/**
 * Attach delegated event to an HTMLElement or NodeList
 * @param eventName {string} Name of the event.
 * @param parent {HTMLElement | Nodelist} Can be either a selector or a list of selectors
 * @param targetSelector {string} Target selector.
 * @param fn {function} Listener function
 * @param options {object} An options object that specifies
 * characteristics about the event listener. The available options are:
 * @param one {Boolean} event is attached only once and removed after being listened capture, once, passive.
 * See more info: (https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener)
 */
export function delegate(eventName, parent, targetSelector, fn, options = {}, one) {
    if (typeof eventName !== 'string') {
        throw new Error('eventName has to be a string', parent);
    }

    if (!parent) {
        return;
    }

    if (typeof fn !== 'function') {
        throw new Error('Handler function is mandatory', parent);
    }

    // Generate and attribute an ID for the element
    const eventId = generateEventId(parent);

    // A eventName can be also a list of many event separated by a space like jQuery
    const events = eventName.split(' ');

    const newFn = (event) => {
        const target = event.target.closest(targetSelector);

        if (!target) {
            return;
        }
        if (!parent.contains(target)) {
            return;
        }
        fn(event, target);
    };
    newFn.originFn = fn;

    events.forEach((event) => {
        if (parent) {
            if (parent instanceof HTMLElement
                || parent instanceof window.Window
                || parent instanceof window.Document
            ) {
                parent.eventId = eventId;
                registerListener(event, parent, newFn, options, one);
            } else if (parent instanceof NodeList) {
                [...parent].forEach((nodeElement) => {
                    nodeElement.eventId = generateEventId(nodeElement);
                    registerListener(event, nodeElement, newFn, options, one);
                });
            }
        }
    });
}
