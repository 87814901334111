const breakpoints = {
    SMALL: {
        min: 0,
        max: 767,
    },
    MEDIUM: {
        min: 768,
        max: 1039,
    },
    LARGE: {
        min: 1040,
    },
};

export default breakpoints;
