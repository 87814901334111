/**
 * @param {string} str
 * @param {*} defaultValue
 * @returns {boolean}
 */
export function parse(str, defaultValue = {}) {
    try {
        return JSON.parse(str) || defaultValue;
    } catch (ex) {
        return defaultValue;
    }
}
