import { Event } from 'core/Event';
import { on, off, trigger } from 'tools/event';
import { debounce } from 'tools/debounce';
import { deepMerge } from 'tools/deepMerge';
import { mediaQuery } from 'tools/mediaQuery';
import * as JSONUtils from 'tools/json';

/* eslint no-console: 0 */
export default class Component {
    constructor(element, options = {}) {
        if (!element) {
            throw new Error('Your Class has to be instantiated with an HTMLElement');
        }
        this._componentSelector = 'data-component';
        this.element = element;
        this.name = this._getConstructorName();
        this.selectors = {}; // store the component children selectors
        this.cache = {}; // store miscellaneous cache data
        this.state = {}; // store the component states
        this.renderUrl = null; // link to related server render endpoint for this component (ComponentDataService-Html)
        this.consentTracking = false;
        this._createId();
        this._setConsentTracking();
        Event.on('ConsentTracking:applied', this.consentTrackingApplied, this);// TODO: check if it's needed
        this.analytics = {};
        this._initAnalytics();
        this.element.setAttribute(`${this._componentSelector}-id`, this.id);

        // Initial options of the component,
        // we need to keep reference in order to re-apply responsive options
        this._defaultOptions = {
            breakpoints: null,
            isMediaChange: false,
            registerChildren: false,
            resizable: false,
        };

        this._componentAttributeOptions = this.element.getAttribute(`${this._componentSelector}-options`) || '{}';

        try {
            this._componentAttributeOptions = JSON.parse(this._componentAttributeOptions);
        } catch (e) {
            console.warn(`Please check that the options you have passed for "${this.name}" respect JSON format`);
        }

        // Component can be loaded for a specific context (small, medium, large)
        this._context = this.element.getAttribute(`${this._componentSelector}-context`);

        // Component can be frozen due to context change. This variable should never be used.
        this._frozen = false;

        this._initialOptions = deepMerge.all([
            this._defaultOptions,
            options,
            this._componentAttributeOptions,
        ]);

        this._setOptions(this._initialOptions, false);

        if (this.options.resizable) {
            on(
                `resize.${this.id}`,
                window,
                debounce(this.onResize.bind(this), 100),
                {},
            );
        }

        if (this._context
            || this.options.breakpoints
            || this.options.isMediaChange
        ) {
            Event.on('MediaQuery:changed', this._onMediaQueryChange, this);
        }

        trigger('component:init', this.element, { bubbles: true });

        if (this.options.registerChildren) {
            this._registerChildrenComponents();
            on(
                'component:init component:destroy',
                this.element,
                // Parent component can have many children
                // so it's better to debounce event handler calls
                debounce(this._registerChildrenComponents.bind(this), 100),
            );
        }

        this.initCache();
        this.initState();
        this.bindEvents();
        this.afterInit();
    }

    _initAnalytics() {
        if (!this.consentTracking) {
            return;
        }

        const analytics = this.element.getAttribute('data-analytics');

        let data;

        if (analytics) {
            data = JSONUtils.parse(analytics);

            if (!(data.products instanceof Array)) {
                data.products = [data.products];
            }
        }

        if (data) {
            // attach data to the component
            this.analytics = data;
        }
    }

    /**
     * Sets consent tracking
     * @private
     */
    _setConsentTracking() {
        if (document.head.querySelector('[name~=consentTracking]')) {
            this.consentTracking = true;
        }
    }

    /**
     * Add all subtree components to this.childrenComponents object
     * this.option.registerChildren has to be true
     * @private
     */
    _registerChildrenComponents() {
        this.childrenComponents = {}; // flush childrenComponents object before register
        const unsortedChildrenComponents = this.element.querySelectorAll(`[${this._componentSelector}]`);

        unsortedChildrenComponents.forEach((child) => {
            const componentType = child.getAttribute(this._componentSelector);

            if (!this.childrenComponents[componentType]) {
                this.childrenComponents[componentType] = [];
            }

            this.childrenComponents[componentType].push(child);
        });
    }

    /**
     * Returns current constructor name
     * @private
     * @returns {string}
     */
    _getConstructorName() {
        return this.constructor.name || this.constructor.toString().split('(')[0].replace(/function\s*/, '');
    }

    /**
     * Generate an ID for the newly created component,
     * based on the Class name
     * @private
     */
    _createId() {
        this.id = `${this.name}_${Math.random().toString(36).substr(2, 16)}`;
    }

    /**
     * Replaces element with the content
     * @private
     * @param {string} content - String with valid HTML
     * @returns {HTMLElement} new HTML element
     */
    _replaceElement(content) {
        const div = document.createElement('div');
        div.innerHTML = content;
        const newElement = div.querySelector(`[${this._componentSelector}]`);
        Event.emit('ComponentRegistry:registerElement', newElement); // TODO check if we need it
        this.element.replaceWith(newElement);
        return newElement;
    }

    /**
     * Set the options of the component
     * @private
     * @param {Object} options - Component's options
     * @param {boolean} reset - Reset options with the new ones
    */
    _setOptions(options, reset) {
        if (!options) {
            return;
        }

        if (reset) {
            this.options = {};
        }

        if (options.breakpoints) {
            // check if we have any breakpoint configuration available for the current viewport
            const responsiveOptions = this._getCurrentViewportOptions(options.breakpoints);
            this.options = Object.assign({}, this.options, options); // TODO check

            // Overload global options by contextual viewport options
            if (responsiveOptions) {
                this.options = deepMerge(this.options, responsiveOptions);
            }
        } else {
            this.options = Object.assign({}, this.options, options); // TODO check
        }
    }

    /**
     * Get contextual configuration specific to a viewport
     * @private
     * @param {Object} breakpoints - Breakpoints object
     * @returns {Object} responsive options
     */
    _getCurrentViewportOptions(breakpoints) {
        let responsiveOptions;
        Object.entries(breakpoints).some(([mq, mqOptions]) => {
            const isCurrentMQ = mediaQuery.is(mq);
            if (isCurrentMQ) {
                responsiveOptions = mqOptions;
            }
            return isCurrentMQ;
        });

        return responsiveOptions;
    }

    /**
     * _reInit calls automatically after a component
     * change back context (small, medium, large).
     * It works only in the case the component
     * has "data-component-context" attribute
     * @private
     */
    _reInit() {
        this.initCache();
        this.initState();
        this.bindEvents();
        this.afterInit();

        if (window.app.debug) {
            console.log('%c Re-initialize frozen component: ', 'color: Orange', this.element);
        }
    }

    /**
    * Render the appropriate template based on the data and template provided
    * @param {Object} data - Data model used in the template
    * @param {Object} template - HBS template
    */
    render(data, template) {
        const content = template.default(data);
        const newElement = this._replaceElement(content);
        if (typeof data.afterRender === 'function') {
            data.afterRender(newElement);
        }
    }

    /**
     * Called when a breakpoint has changed.
     * this function should never be extended,
     * only the public onMediaQueryChange can be
     * @private
     */
    _onMediaQueryChange() {
        this.onBeforeMediaQueryChange();
        // refresh the current options
        if (this._initialOptions.breakpoints) {
            // Configuration may differ from a viewport to another.
            // We need to refresh them and adjust it to the current viewport
            this._setOptions(this._initialOptions, true);
        }

        if (this._context) {
            const isInContext = mediaQuery.is(this._context);

            // If the component was frozen (context change), we need to _reInit it
            if (this._frozen && isInContext) {
                this._frozen = false;
                this._reInit();
            } else if (!isInContext) {
                if (window.app.debug) {
                    console.log('%c Component frozen: ', 'color: #5a65ab; font-weight:bold', this.element);
                }
                this._frozen = true;
                this.destroy();
            }
        }
        this.onMediaQueryChange();
    }

    /**
     * Destroy function for Component registry functionality
     */
    _destroy() {
        if (this.options.resizable) {
            off(`resize.${this.id}`, window);
        }

        if (this._context
            || this.options.breakpoints
            || this.options.isMediaChange
        ) {
            Event.removeListener('MediaQuery:changed', this._onMediaQueryChange, this);
        }

        if (this.options.registerChildren) {
            off('component:init component:destroy', this.element);
        }

        this.destroy();
    }

    /**
     * Cache DOM elements which will be used in a component.
     * Cached DOM elements should be stored in
     * this.selectors object
     */
    initCache() {
        // Can be overloaded
    }

    /**
     * Init component states.
     * Component's states have
     * to be stored in this.state object
     */
    initState() {
        // Can be overloaded
    }

    /**
     * Bind all events
     * If we can think about any interaction
     * or how component works - it happens as
     * a reaction to events.
     * So this method is to attach events
     * to a component's instance
     * Please use bind for the handlers.
     * No anonymous nor arrow functions have to be used for handlers.
     */
    bindEvents() {
        // Can be overloaded
    }

    /**
     * This hook is used for any action that
     * should be performed after the component init
    */
    afterInit() {
        // Can be overloaded
    }

    /**
     * Executed when ComponentsRegistry calls this method
     * after window.onload has been triggered
     */
    initLoad() {
        // can be overloaded
    }

    /**
     * Executed before media query changes
     * in order to make this hook work:
     * data-component-context
     * or this.options.breakpoints
     * or this.options.isMediaChange have to be defined/true
     */
    onBeforeMediaQueryChange() {
        // can be overloaded
    }

    /**
     * Executed when media query changes
     * in order to make this hook work:
     * data-component-context
     * or this.options.breakpoints
     * or this.options.isMediaChange have to be defined/true
     */
    onMediaQueryChange() {
        // can be overloaded
    }

    /**
     * Executes when viewport changes it's size.
     * To make this hook work this.options.resizable has to be true
     */
    onResize() {
        throw new Error('Override this function to have resizable components');
    }

    /**
     * Calls _setConsentTracking and triggers an event,
     * which should be catched in all necessary components
     */
    consentTrackingApplied() {
        this._setConsentTracking();
        this._initAnalytics();
        trigger('component:consentTracking', this.element);
    }

    sendAnalyticsEvent() {
        Event.emit('Component:sendAnalytics', this.analytics);
    }

    /**
     * It's a best practice to remove events attached to a DOM element.
     * It would avoid any memory leaks
     */
    destroy() {
        // Can be overloaded
    }
}
