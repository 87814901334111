import 'public-path';
import Registry from 'core/ComponentRegistry';
import { mediaQuery } from 'tools/mediaQuery';

window.app = window.app || {};

/**
 * DOM ready handler
 */
function ready() {
    Registry.run();
    mediaQuery._init();
}

/**
 * Application initialization
 */
function init() {
    if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
        ready();
    } else {
        document.addEventListener('DOMContentLoaded', ready);
    }
}

Promise.resolve().then(() => {
    window.app = Object.assign({}, window.app, {
        mountedComponents: Registry.mountedComponents,
        getComponentsByName: Registry.getComponentsByName.bind(Registry),
        getComponent: Registry.getComponent.bind(Registry),
        debug: window.location.search.indexOf('debug') !== -1,
    });

    init();
});
