export default {
    /*
     * Global Components
     */
    'global/Accordion': () => import('global/Accordion'),
    'global/FilterAccordion': () => import('global/FilterAccordion'),
    'global/FilterAccordionV2': () => import('global/FilterAccordionV2'),
    'global/BackToTop': () => import('global/BackToTop'),
    'global/Carousel': () => import('global/Carousel'),
    'global/Dropdown': () => import('global/Dropdown'),
    'global/DropdownNavigation': () => import('global/DropdownNavigation'),
    'global/EventTrigger': () => import('global/EventTrigger'),
    'global/Form': () => import('global/Form'),
    'global/Link': () => import('global/Link'),
    'global/Tabs': () => import('global/Tabs'),
    'global/Video': () => import('global/Video'),
    'global/EmailSubscribe': () => import('global/EmailSubscribe'),
    'global/Input': () => import('global/Input'),
    'global/MiniCart': () => import('global/MiniCart'),
    'global/PopUpFromCookie': () => import('global/PopUpFromCookie'),
    'global/CookiesDisabled': () => import('global/CookiesDisabled'),
    'global/FadingCarousel': () => import('global/FadingCarousel'),
    'global/QuickView': () => import('global/QuickView'),
    'global/Button': () => import('global/Button'),
    'global/Select': () => import('global/Select'),
    'global/OutsideClick': () => import('global/OutsideClick'),
    'global/Toggle': () => import('global/Toggle'),
    'global/Tooltip': () => import('global/Tooltip'),
    'global/WayDown': () => import('global/WayDown'),
    'global/ToggleContainer': () => import('global/ToggleContainer'),
    'global/ToggleMain': () => import('global/ToggleMain'),
    'global/Sepomex': () => import('global/Sepomex'),
    'global/ShowContentModal': () => import('global/ShowContentModal'),
    'global/GTM': () => import('global/GTM'),
    'global/Player': () => import('global/Player'),
    'global/GenerateBarcode': () => import('global/GenerateBarcode'),
    'global/Print': () => import('global/Print'),
    'global/QuaggaJS': () => import('global/QuaggaJS'),
    'global/Recaptcha': () => import('global/Recaptcha'),
    'global/LazyLoad': () => import('global/LazyLoad'),
    'global/DateRangePicker': () => import('global/DateRangePicker'),
    'global/DateAirRangePicker': () => import('global/DateAirRangePicker'),
    'global/TemplateLoader': () => import('global/TemplateLoader'),
    'global/ToastContainer': () => import('global/ToastContainer'),
    'global/WishlistButton': () => import('global/WishlistButton'),
    // Header components
    'header/CategoriesNavigation': () => import('header/CategoriesNavigation'),
    'header/Header': () => import('header/Header'),
    'header/QuickSearch': () => import('header/QuickSearch'),
    'header/SearchSuggestions': () => import('header/SearchSuggestions'),
    'header/WishlistController': () => import('header/WishlistController'),
    // Cart components
    'cart/Cart': () => import('cart/Cart'),
    'cart/PromoCode': () => import('cart/PromoCode'),
    'cart/PromoCodeOnlyOne': () => import('cart/PromoCodeOnlyOne'),
    'cart/LineItemQuantity': () => import('cart/LineItemQuantity'),
    'cart/ItemQuantityBundle': () => import('cart/ItemQuantityBundle'),
    'cart/FixedAmountLineItemQuantity': () => import('cart/FixedAmountLineItemQuantity'),
    'cart/Totals': () => import('cart/Totals'),
    'cart/TotalEstimated': () => import('cart/TotalEstimated'),
    'cart/CartAccordion': () => import('cart/CartAccordion'),
    'cart/LineItemWishlistButton': () => import('cart/LineItemWishlistButton'),
    // Checkout components
    'checkout/CheckoutTotals': () => import('checkout/CheckoutTotals'),
    'checkout/Shipping': () => import('checkout/Shipping'),
    'checkout/CelebraShipping': () => import('checkout/CelebraShipping'),
    'checkout/Billing': () => import('checkout/Billing'),
    'checkout/Payment': () => import('checkout/Payment'),
    'checkout/Summary': () => import('checkout/Summary'),
    'checkout/Checkout': () => import('checkout/Checkout'),
    'checkout/Addresses': () => import('checkout/Addresses'),
    'checkout/Installment': () => import('checkout/Installment'),
    'checkout/ProgressBar': () => import('checkout/ProgressBar'),
    'checkout/CheckoutRegistration': () => import('checkout/CheckoutRegistration'),
    'checkout/Stores': () => import('checkout/Stores'),
    'checkout/CreditCard': () => import('checkout/CreditCard'),
    'checkout/PalacioCard': () => import('checkout/PalacioCard'),
    'checkout/StoredCard': () => import('checkout/StoredCard'),
    'checkout/PaymentMethod': () => import('checkout/PaymentMethod'),
    'checkout/Points': () => import('checkout/Points'),
    'checkout/PaypalFinancingOptions': () => import('checkout/PaypalFinancingOptions'),
    'checkout/ThreatMetrix': () => import('checkout/ThreatMetrix'),
    'checkout/SavedAddresses': () => import('checkout/SavedAddresses'),
    'checkout/MonedasPayment': () => import('checkout/MonedasPayment'),
    'checkout/PromoCode': () => import('checkout/CheckoutPromoCode'),
    'checkout/AverageDeliveryTime': () => import('checkout/AverageDeliveryTime'),
    'checkout/EditPickUpInfo': () => import('checkout/EditPickUpInfo'),
    // Product components
    'product/BonusProducts': () => import('product/BonusProducts'),
    'product/AddToCartButton': () => import('product/AddToCartButton'),
    'product/ContextualMessages': () => import('product/ContextualMessages'),
    'product/SetConfigurationHub': () => import('product/SetConfigurationHub'),
    'product/Zoom': () => import('product/Zoom'),
    'product/ZoomV2': () => import('product/ZoomV2'),
    'product/Pinch': () => import('product/Pinch'),
    'product/PDP': () => import('product/PDP'),
    'product/PDPV2': () => import('product/PDPV2'),
    'product/SizeChart': () => import('product/SizeChart'),
    'product/Quantity': () => import('product/Quantity'),
    'product/Swatches': () => import('product/Swatches'),
    'product/Attributes': () => import('product/Attributes'),
    'product/Price': () => import('product/Price'),
    'product/Options': () => import('product/Options'),
    'product/Availability': () => import('product/Availability'),
    'product/ChangesVariations': () => import('product/ChangesVariations'),
    'product/ProductSetDetail': () => import('product/ProductSetDetail'),
    'product/ProductSetDetailV2': () => import('product/ProductSetDetailV2'),
    'product/BundleQuantity': () => import('product/BundleQuantity'),
    'product/NotifyMe': () => import('product/NotifyMe'),
    'product/ProductDescription': () => import('product/ProductDescription'),
    'product/Specs': () => import('product/Specs'),
    'product/СustomAmount': () => import('product/СustomAmount'),
    'product/SyteRecommendations': () => import('product/SyteRecommendations'),
    'product/ModifaceVTO': () => import('product/ModifaceVTO'),
    'product/ModifaceButton': () => import('product/ModifaceButton'),
    'product/ProductWishlistButton': () => import('product/ProductWishlistButton'),
    'product/BankPromotions': () => import('product/BankPromotions'),
    'product/MiniProductWishlistButton': () => import('product/MiniProductWishlistButton'),
    'product/ToastContainerForSetHub': () => import('product/ToastContainerForSetHub'),
    'product/EinsteinEvents': () => import('product/EinsteinEvents'),
    'product/ProductDesktopCarousel': () => import('product/ProductDesktopCarousel'),
    'product/ProductSyteButton': () => import('product/ProductSyteButton'),
    'product/DiscountBadge': () => import('product/DiscountBadge'),
    // Search components
    'search/Search': () => import('search/Search'),
    'search/LoadMoreButton': () => import('search/LoadMoreButton'),
    'search/EditorialText': () => import('search/EditorialText'),
    'search/Refinements': () => import('search/Refinements'),
    'search/SortSelect': () => import('search/SortSelect'),
    'search/SortRadio': () => import('search/SortRadio'),
    'search/Swatches': () => import('search/Swatches'),
    'search/ProductTile': () => import('search/ProductTile'),
    'search/Brands': () => import('search/Brands'),
    'product/ProductCarousel': () => import('product/ProductCarousel'),
    'product/ProductCarouselV2': () => import('product/ProductCarouselV2'),
    'search/Pagination': () => import('search/Pagination'),
    'search/FilterToggle': () => import('search/FilterToggle'),
    'search/Tags': () => import('search/Tags'),
    'search/TileWishlistButton': () => import('search/TileWishlistButton'),
    // Page Designer
    'pageDesigner/BigDaddy': () => import('pageDesigner/BigDaddy'),
    'pageDesigner/Picture': () => import('pageDesigner/Picture'),
    'pageDesigner/FAQ': () => import('pageDesigner/FAQ'),
    'pageDesigner/Page': () => import('pageDesigner/Page'),
    // Page Designer - Mundo de lujo
    'pageDesigner/LuxuryMainHero': () => import('pageDesigner/LuxuryMainHero'),
    'pageDesigner/BeautyBookingModal': () => import('pageDesigner/BeautyBookingModal'),
    'pageDesigner/BeautyServiceLocationsModal': () => import('pageDesigner/BeautyServiceLocationsModal'),
    // Page Designer - Portal de crédito
    'pageDesigner/Benefit': () => import('pageDesigner/Benefit'),
    'pageDesigner/Benefits': () => import('pageDesigner/Benefits'),
    'pageDesigner/ContentTabs': () => import('pageDesigner/ContentTabs'),
    'pageDesigner/CountdownTimer': () => import('pageDesigner/CountdownTimer'),
    'pageDesigner/FAQPC': () => import('pageDesigner/FAQPC'),
    'pageDesigner/GoToApp': () => import('pageDesigner/GoToApp'),
    'pageDesigner/MenuPC': () => import('pageDesigner/MenuPC'),
    'pageDesigner/ModalAuto': () => import('pageDesigner/ModalAuto'),
    'pageDesigner/StickyButton': () => import('pageDesigner/StickyButton'),
    'pageDesigner/PicturePC': () => import('pageDesigner/PicturePC'),
    'pageDesigner/InactivityLogout': () => import('pageDesigner/InactivityLogout'),

    // Account components
    'account/Login': () => import('account/Login'),
    'account/Registration': () => import('account/Registration'),
    'account/ResetPassword': () => import('account/ResetPassword'),
    'account/NewPassword': () => import('account/NewPassword'),
    'account/SaveAddress': () => import('account/SaveAddress'),
    'account/Address': () => import('account/Address'),
    'account/ChangePassword': () => import('account/ChangePassword'),
    'account/ChangePasswordApp': () => import('account/ChangePasswordApp'),
    'account/EditProfile': () => import('account/EditProfile'),
    'account/AddressDefault': () => import('account/AddressDefault'),
    'account/OrderCancellation': () => import('account/OrderCancellation'),
    'account/OrderReturn': () => import('account/OrderReturn'),
    'account/PaymentMethods': () => import('account/PaymentMethods'),
    'account/SaveCreditCard': () => import('account/SaveCreditCard'),
    'account/AddressBook': () => import('account/AddressBook'),
    'account/AddressBookApp': () => import('account/AddressBookApp'),
    'account/Wishlist': () => import('account/Wishlist'),
    'account/WishlistRemoveButton': () => import('account/WishlistRemoveButton'),
    'account/WishlistPagination': () => import('account/WishlistPagination'),
    'account/WishlistMasterConfigure': () => import('account/WishlistMasterConfigure'),
    'account/Navigation': () => import('account/Navigation'),
    'account/Newsletter': () => import('account/Newsletter'),
    'account/Coins': () => import('account/Coins'),
    'account/SuperTicket': () => import('account/SuperTicket'),
    'account/DeleteAccount': () => import('account/DeleteAccount'),
    // Order components
    'order/OrderHistory': () => import('order/OrderHistory'),
    'order/OrderHistoryApp': () => import('order/OrderHistoryApp'),
    // Insurance components
    'insurance/Insurance': () => import('insurance/Insurance'),
    'insurance/InsuranceHome': () => import('insurance/InsuranceLanding'),
    // Bonus Product
    'bonus/Swatches': () => import('bonus/Swatches'),
    'bonus/BonusProductItem': () => import('bonus/BonusProductItem'),
    'bonus/Attributes': () => import('bonus/Attributes'),
    // Quickview
    'quickview/QuickviewCarousel': () => import('quickview/QuickviewCarousel'),
    'quickview/UpdateCartButton': () => import('quickview/UpdateCartButton'),
    // ContactUs
    'contactUs/ContactUsPage': () => import('contactUs/ContactUsPage'),
    'contactUs/ContactUsForm': () => import('contactUs/ContactUsForm'),
    // Celebra
    'celebra/EventPublicForm': () => import('celebra/EventPublicForm'),
    'celebra/Celebra': () => import('celebra/Celebra'),
    'celebra/CelebraPLP': () => import('celebra/CelebraPLP'),
    'celebra/CelebraGiftsTable': () => import('celebra/CelebraGiftsTable'),
    'celebra/CelebraMdrCreation': () => import('celebra/CelebraMdrCreation'),
    'celebra/CelebraCreateEventPassword': () => import('celebra/CelebraCreateEventPassword'),
    'celebra/CelebraCreateEvent': () => import('celebra/CelebraCreateEvent'),
    'celebra/CelebraEventType': () => import('celebra/CelebraEventType'),
    'celebra/PostalCodeValidation': () => import('celebra/PostalCodeValidation'),
    'celebra/CelebraServiceError': () => import('celebra/CelebraServiceError'),
    'celebra/CelebraCreationEventDetails': () => import('celebra/CelebraCreationEventDetails'),
    // Revieve
    'revieve/RevieveTooltip': () => import('revieve/RevieveTooltip'),
    // YaloChat - WhatsApp
    'yalochat/YaloChat': () => import('yalochat/YaloChat'),

    // Accertify - Account-Protection
    'accertify/AccertifyAnchorDataCollector': () => import('accertify/AccertifyAnchorDataCollector'),

    // CustomOrderOnBeHalf
    'CustomOrderOnBehalf/OrderOnBehalf': () => import('CustomOrderOnBehalf/OrderOnBehalf'),
    'account/LoginAgent': () => import('account/LoginAgent'),
    'CustomOrderOnBehalf/FormForAssistedSale': () => import('CustomOrderOnBehalf/FormForAssistedSale'),
    'CustomOrderOnBehalf/CopyOrderNumber': () => import('CustomOrderOnBehalf/CopyOrderNumber'),
    'CustomOrderOnBehalf/SearchUnpaidOrders': () => import('CustomOrderOnBehalf/SearchUnpaidOrders'),

    // Credit Portal Dashboard - components
    'portalCredito/Dashboard': () => import('portalCredito/Dashboard'),
    'portalCredito/EnrollCard': () => import('portalCredito/EnrollCard'),
    'portalCredito/CardOptionsManager': () => import('portalCredito/CardOptionsManager'),
    'portalCredito/BalanceDetails': () => import('portalCredito/BalanceDetails'),
    'portalCredito/PointsDetails': () => import('portalCredito/PointsDetails'),
    'portalCredito/ToggleCardActivation': () => import('portalCredito/ToggleCardActivation'),
    'portalCredito/ConsultCardApplication': () => import('portalCredito/ConsultCardApplication'),
    'portalCredito/CardTransactions': () => import('portalCredito/CardTransactions'),
    'portalCredito/CardManager': () => import('portalCredito/CardManager'),
    'portalCredito/CardTransactionsTable': () => import('portalCredito/CardTransactionsTable'),
    'portalCredito/CardsDropdown': () => import('portalCredito/CardsDropdown'),
    'portalCredito/CardStatements': () => import('portalCredito/CardStatements'),
    'portalCredito/DashboardPoints': () => import('portalCredito/DashboardPoints'),
    'portalCredito/PortalGateway': () => import('portalCredito/PortalGateway'),
    'portalCredito/InactiveUser': () => import('portalCredito/InactiveUser'),
    'portalCredito/CardPaymentProcess': () => import('portalCredito/CardPaymentProcess'),
    'portalCredito/SelectDebitCardStep': () => import('portalCredito/SelectDebitCardStep'),
    'portalCredito/PaymentConfirmation': () => import('portalCredito/PaymentConfirmation'),
    'portalCredito/PaymentConfirmationFooter': () => import('portalCredito/PaymentConfirmationFooter'),
};
