import Component from 'core/Component';
import { Event } from 'core/Event';
import { trigger } from 'tools/event';
import { mediaQuery } from 'tools/mediaQuery';
import componentsMap from 'components-map';

let instance;
let isWindowLoaded = false;

window.app = {};
window.app.debug = false;

/* eslint no-console: 0 */
/* eslint valid-jsdoc: 0 */

class ComponentRegistry {
    constructor() {
        if (!instance) {
            instance = this;
            this._componentSelector = 'data-component';
            this.components = {};

            // The object will store all components that
            // were not registered due to contextual loading
            this.unregisteredComponents = {};
        }

        return instance;
    }

    run() {
        this._initComponents();
        this._bindEvents();
        this._componentsObserver();
    }

    /**
     * Observes document for changes in body
     * @private
     */
    _componentsObserver() {
        const observer = new MutationObserver(this._componentsObserverHandler.bind(this));
        const config = {
            attributes: false,
            characterData: false,
            childList: true,
            subtree: true,
        };

        observer.observe(document.body, config);
    }

    /**
     * Mutation event handler
     * @private
     * @param {Mutation} mutations
     */
    _componentsObserverHandler(mutations) {
        const mutationsCollection = mutations;

        mutationsCollection.forEach((mutation) => {
            const { addedNodes, removedNodes } = mutation;
            this.currentMutation = mutation;
            addedNodes.forEach(this._registerAddedElements, this);
            removedNodes.forEach(this._destroyRemovedComponents, this);
        });
    }

    /**
     * Registers dynamically added components
     * @private
     * @param {HTMLElement} node
     */
    _registerAddedElements(node) {
        const currentNode = node;

        if (this._mutationHasComponents(currentNode)) {
            if (currentNode.hasAttribute(this._componentSelector)) {
                this.registerElement(currentNode);
            } else {
                this.registerChildren(currentNode);
            }
        }
    }

    /**
     * Destroys dynamically removed components
     * @private
     * @param {HTMLElement} node
     */
    _destroyRemovedComponents(node) {
        const currentNode = node;
        if (this._mutationHasComponents(currentNode)) {
            // Array which will store Components ID to destroy
            const componentsIDsToDestroy = [];
            if (currentNode.hasAttribute(this._componentSelector)) {
                currentNode.removeAttribute(`${this._componentSelector}-id`);
                componentsIDsToDestroy.push(this._getComponentId(currentNode));
            }
            // Get all components by selector
            let innerComponents = currentNode.querySelectorAll(`[${this._componentSelector}]`);

            innerComponents = [...innerComponents]
                .map((innerComponent) => {
                    innerComponent.removeAttribute(`${this._componentSelector}-id`);
                    return this._getComponentId(innerComponent);
                })
                .filter(innerComponent => !!innerComponent);

            componentsIDsToDestroy.push(...innerComponents);

            this.unregister(componentsIDsToDestroy);

            trigger('component:destroy', this.currentMutation.target, { bubbles: true });
        }
    }

    /**
     * Checks if an HTML element is a component or
     * has components in its sub-tree
     * @private
     * @param {HTMLElement} element
     * @returns {boolean}
     */
    _mutationHasComponents(element) {
        const currentElement = element;
        const isHtmlElement = currentElement instanceof HTMLElement;
        const isComponent = isHtmlElement && currentElement.hasAttribute(this._componentSelector);
        const containsComponents = isHtmlElement
            && currentElement.querySelector(`[${this._componentSelector}]`) !== null;

        return isComponent || containsComponents;
    }

    /**
     * Returns component ID from an HTML element
     * @private
     * @param {HTMLElement} node
     * @returns {boolean|string} - returns false or component's ID
     */
    _getComponentId(element) {
        const currentElement = element;

        if (this._isRegistered(currentElement)) {
            return currentElement.getAttribute(`${this._componentSelector}-id`);
        }

        return false;
    }

    _bindEvents() {
        window.addEventListener('load', this._onWindowLoad.bind(this));

        Event.on('MediaQuery:changed', this._onMediaQueryChange, this);
        Event.on('ComponentRegistry:registerElement', this.registerElement, this);

        // It's managed by Mutation Observer, but leaving it here just in case
        Event.on('ComponentRegistry:registerChildren', this.registerChildren, this);
    }

    /**
     * Window onload handler function to call each component
     * initLoad method
     * @private
     */
    _onWindowLoad() {
        isWindowLoaded = true;

        // eslint-disable-next-line
        Object.entries(this.components).forEach(([key, comp]) => {
            if (!comp.loaded) {
                comp.loaded = true;
                comp.initLoad();
            }
        });
    }

    /**
     * Media query change handler
     * registers contextual components
     * @private
     */
    _onMediaQueryChange() {
        this.registerContextualComponents();
    }

    /**
     * Register a single element and it's children if flag is enabled
     * @param {HTMLElement} element
     * @param {boolean} registerChildren
     */
    registerElement(element, registerChildren = true) {
        if (element.hasAttribute(this._componentSelector)) {
            if (this._isEligible(element)) {
                return this.importComponent(element).then(() => {
                    if (registerChildren) {
                        return this.registerChildren(element);
                    }
                    return false;
                });
            }

            if (registerChildren) {
                return this.registerChildren(element);
            }
        }

        console.warn('You are trying to register a non-component', element);

        return Promise.reject(element);
    }

    /**
     * Register children of a container
     * @param {HTMLElement} root
     */
    registerChildren(root = document) {
        root.querySelectorAll(`[${this._componentSelector}]`).forEach((element) => {
            if (this._isEligible(element)) {
                this.importComponent(element);
            }
        });
    }

    /**
     * Register contextual components
     */
    registerContextualComponents() {
        Object.keys(this.unregisteredComponents).forEach((viewport) => {
            if (mediaQuery.is(viewport)) {
                const elements = this.unregisteredComponents[viewport];
                if (elements.length) {
                    elements.forEach((element) => {
                        this.registerElement(element, false);
                    });
                    this.unregisteredComponents[viewport] = [];
                }
            }
        });
    }

    /**
     * Rules to define if a component can be registered
     * @private
     * @param {HTMLElement} element
     */
    _isEligible(element) {
        if (this._isRegistered(element)) {
            return false;
        }

        if (this.isContext(element)) {
            return true;
        }

        const context = element.getAttribute(`${this._componentSelector}-context`);

        if (context) {
            if (!this.unregisteredComponents[context]) {
                this.unregisteredComponents[context] = [];
            }
            this.unregisteredComponents[context].push(element);
        }

        return false;
    }

    /**
     * Fetches the component defined at the element from server and registers it.
     * @param {HTMLElement|Object} element an element defining a Component
     */
    importComponent(element) {
        let currentElement = element;

        if (!currentElement) {
            return Promise.reject(new Error('ImportComponent is missing a mandatory param'));
        }

        // If 2 same elements are being imported at the same time.
        // We should not import any other module for the same element
        if (currentElement._loading) {
            return Promise.resolve();
        }

        let componentName;
        let componentOptions = {};
        let isHTMLElement = currentElement instanceof HTMLElement;
        if (isHTMLElement) {
            // If component already registered, we don't import it
            if (this._isRegistered(currentElement)) {
                return Promise.reject(new Error(`${currentElement} is already registered`));
            }
            componentName = currentElement.getAttribute(this._componentSelector);
        } else {
            componentName = currentElement.name;
            componentOptions = currentElement.options;

            if (currentElement.elmt) {
                isHTMLElement = true;
                currentElement = element.elmt;
            }
        }

        element._loading = true;

        const componentImport = componentsMap[componentName];
        if (!componentImport) {
            return Promise.reject(
                new Error(`Please register ${componentName} in 'components-map.js'`),
            );
        }

        return componentImport().then(({ default: Comp }) => {
            if (!Comp) {
                throw new Error(`Export your ${componentName} module as default`);
            }
            const elmt = isHTMLElement ? currentElement : null;
            const comp = new Comp(elmt, componentOptions || {});

            this.register(comp);

            return comp;
        });
    }

    /**
     * Init components on a page
     */
    _initComponents() {
        this.pageComponents.forEach((component) => {
            // the component can be loaded under 3 conditions
            /**
             * 1. To be in viewport
             * 2. Can be forced to be loaded even if it's out of viewport.
             * 3. Can be loaded for a given context (media query) small, large,
             */
            if (!this._isEligible(component)) {
                return;
            }

            this.importComponent(component);
            this.unLoadComponentsCount -= 1;
        });
    }

    /**
     * Some component can be visible in the viewport but would not require any javascript behavior
     * As we use the same html for all viewport, we do want to contextualize the loading
     * i.e: <div data-component="global/Accordion" data-component-context="small only"></div>;
     * In this case, component will be loaded only on mobile.
     * Remember the context is mobile first if value is set to medium - it will be loaded
     * for medium and above
     * @param {HTMLElement} component
     * @returns {boolean}
     */
    isContext(component) {
        const componentContext = component.getAttribute(`${this._componentSelector}-context`);

        if (!componentContext) {
            return true;
        }

        let mq = 'small';
        if (componentContext !== '') {
            mq = componentContext;
        }

        return mediaQuery.is(mq);
    }

    /**
     * Register the component into the central component registry
     * @param {Component} comp
     */
    register(comp) {
        // Check if the component extend from the Component Class
        if (comp instanceof Component) {
            Event.emit(`ComponentRegistry:register.${comp.name}`, {
                name: comp.name,
                id: comp.id,
                element: comp.element,
            });
            trigger('component:ready', comp.element, { bubbles: true });
            this.components[comp.id] = comp;

            // if onload event has been already triggered before component
            // initialization, we call directly the initLoad method
            if (isWindowLoaded && !comp.loaded) {
                comp.loaded = true;
                comp.initLoad();
            }

            if (window.app.debug) {
                console.log('%c New component registered: ', 'color: DodgerBlue', comp);
            }
        } else {
            throw new Error(`${comp.name} needs to extend from the Core Component Class`);
        }
    }

    /**
     * Call the destroy method from the component
     * and clear all the properties attached to it.
     * @param {string|Array} id - String or Array of strings with components ID to destroy
     */
    unregister(ids) {
        let componentIDs = ids;

        if (typeof ids === 'string') {
            componentIDs = [ids];
        }

        componentIDs.forEach((id) => {
            const _comp = this.components[id];

            if (_comp instanceof Object) {
                _comp._destroy();

                if (window.app.debug) {
                    console.log('%c Component destroyed: ', 'color: Red', _comp);
                }

                Event.emit(`ComponentRegistry:unregister.${_comp.name}`, {
                    name: _comp.name,
                    id,
                    element: _comp.element,
                });

                // clean up script to prepare for garbage collection.
                for (const prop in _comp) {
                    if (Object.prototype.hasOwnProperty.call(_comp, prop)) {
                        _comp[prop] = null;
                    }
                }
                delete this.components[id];
            }
        });
    }

    /**
     * Get component by getting its ID
     * @param {string} id
     */
    getComponent(id) {
        return this.components[id] || null;
    }

    /**
     * Get component by name. Form, Input, Carousel, etc..
     * @param {string} name
     * @returns {Array} List of mounted components instances
     */
    getComponentsByName(name) {
        const components = [];
        let componentName = name;

        if (componentName && typeof componentName === 'string') {
            componentName = componentName.toLowerCase();
        } else {
            return components;
        }

        Object.keys(this.components).forEach((componentId) => {
            const component = this.components[componentId];

            if (component.name.toLowerCase() === componentName) {
                components.push(component);
            }
        });

        return components;
    }

    /**
     * Get the list of components that have been mounted already
     * @returns {Object} Object of components instances
     */
    get mountedComponents() {
        return this.components;
    }

    /**
     * Get all components from the page mounted or not
     * @returns {NodeList} List of all components nodes
     */
    get pageComponents() {
        return document.querySelectorAll(`[${this._componentSelector}]`);
    }

    /**
     * Check if the component is registered for an element
     * @private
     * @param {HTMLElement} element
     * @returns {boolean}
     */
    _isRegistered(element) {
        const componentId = element.getAttribute(`${this._componentSelector}-id`);
        if (!componentId) {
            return false;
        }

        const comp = this.getComponent(componentId);

        return !!comp;
    }
}

export default new ComponentRegistry();
